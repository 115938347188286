<template>
    <div id="app">

        <div v-if="layout!=='auth-layout' && showSessionModal === true">
             <b-modal
                v-model="showSessionModal"
                :no-close-on-esc="true"
                :no-close-on-backdrop="true"
                hide-header
                hide-footer
        >
            <div>
            <LoginConfirmModal  @EndSessionModalShow="changeModalStatus" v-if="showAskContiuneSession === true"/>
            <LogoutModal v-else/>
            </div>
            </b-modal>
        </div>

        <component :is="layout">
            <TwoFAModal></TwoFAModal>
            <transition name="fade" mode="out-in">
                <router-view :key="$route.fullPath"></router-view>
            </transition>
        </component>
    </div>
</template>

<script>
import {
  getAPIURLInfo,
  getDomainInfoByCache,
  getAuthenticatedUserByCache,
  getRefleshToken,
  updateRefleshToken,
  removeAuthenticatedUserByCache
} from "@/helpers";
    import moment from 'moment';
    import LoginConfirmModal from './components/popup/LoginConfirmModal';
    import LogoutModal from './components/popup/LogoutModal';
    const default_layout = "auth";
    import VueCountdown from '@chenfengyuan/vue-countdown';
    import TwoFAModal from "./pages/general/TwoFAModal"

    export default {
        name: 'app',
        components:{
            LoginConfirmModal,
            LogoutModal,
            VueCountdown,
            TwoFAModal
        },
        data: () => ({
            domainInfo: getDomainInfoByCache(),
            authenticatedUser: null,
            refleshTokenExp: null,
            expireDate : moment().unix(),
            showSessionModal: false,
            showAskContiuneSession: true,
        }),
        computed: {
            layout() {
                return (this.$route.meta.layout || default_layout) + '-layout';
            }
        },
        methods:{
            updateMetaData() {
                document.title =`${this.domainInfo ? this.domainInfo.brand_name : ''} Crypto Payment Gateway - Login to Start`
                document.querySelector('meta[name="description"]').setAttribute("content", "Login  to proceed the installation of your cryptocurrency payment gateway");
                document.querySelector('meta[name="keywords"]').setAttribute("content", `bitcoin payment gateway, ${this.domainInfo ? this.domainInfo.brand_name: ''} services , ${this.domainInfo ? this.domainInfo.brand_name : ''} gateway service , crypto payment service , `);
            },
            logout() {
                this.$store
                .dispatch("auth/logout")
                .then(data => {
                    this.$router.push('/auth/login');
                })
                .catch(() => {});
            },
            changeModalStatus(data) {
                if (process.env.NODE_ENV === "development") {
                    window.console.log(`changeModalStatus method  (showAskContiuneSession) : ${!data}`);
                    window.console.log(`changeModalStatus method  (showSessionModal) : ${data}`);
                }
                this.showAskContiuneSession = !data;
                this.showSessionModal = data;
            },
        },
          async created(){
            this.updateMetaData();
            this.authenticatedUser = getAuthenticatedUserByCache();
            if(this.authenticatedUser) {
              let tokenPeriod = JSON.parse(localStorage.getItem('ASK_REFLESH_TOKEN_PERIOD')).expiry
              if(tokenPeriod && moment.unix() > tokenPeriod) {
                removeAuthenticatedUserByCache();
                window.location.href = '/auth/login';
              }
            }

            setInterval(() => {
                this.expireDate = moment().unix();
                this.refleshTokenExp =  getRefleshToken();
                if(this.showSessionModal === true && this.showAskContiuneSession === true && this.refleshTokenExp && this.refleshTokenExp.remainTime >= 0 && this.refleshTokenExp.remainTime<=120000){
                    let updateRefleshTokenData = { expiry: this.refleshTokenExp.expiry, remainTime:  this.refleshTokenExp.remainTime-1000};
                    updateRefleshToken(updateRefleshTokenData);
                }
            }, 1000);
        },
        watch: {
            expireDate: function (val) {
                if(this.authenticatedUser){
                    let bufferedEndSessionTime = this.refleshTokenExp ? this.refleshTokenExp.expiry :  0;
                    let endSessionTime = this.authenticatedUser ? this.authenticatedUser.exp :  0;
                    if(bufferedEndSessionTime != null && bufferedEndSessionTime > 0 && val > bufferedEndSessionTime) {
                      if (process.env.NODE_ENV === "development" && this.showAskContiuneSession === true) {
                          window.console.log('show timeout popup', bufferedEndSessionTime != null && bufferedEndSessionTime != 0 && val > bufferedEndSessionTime);
                          window.console.log('bufferedEndSessionTime', bufferedEndSessionTime);
                          window.console.log('bufferedEndSessionTime !=null', bufferedEndSessionTime != null);
                          window.console.log('bufferedEndSessionTime != 0', bufferedEndSessionTime != 0);
                          window.console.log('val ', val);
                          window.console.log('val > bufferedEndSessionTime', val > bufferedEndSessionTime);
                          window.console.log('Now (date format)', moment.unix(val).format());
                          window.console.log('Pre Expire (date format)', moment.unix(bufferedEndSessionTime).format());
                          window.console.log('Expire (date format)', moment.unix(endSessionTime).format());
                          window.console.log('-----------------------------------------------------------------------');
                      }

                        this.showSessionModal = true;
                        if(val > endSessionTime){
                            this.showAskContiuneSession = false;
                        }
                    }
                }

            }
        }
    }
</script>

<style lang="scss">
    @import "~bootstrap/scss/functions";
    @import "~bootstrap/scss/variables";
    @import "~bootstrap/scss/mixins";

    @import "~bootstrap/scss/tooltip";

    @import "~bootstrap-vue/src/variables";
    @import "~bootstrap-vue/src/components/tooltip/index";
    @import "assets/base.scss";

    @font-face {
        font-family: "Barlow";
        src: local("Barlow"),
        url("./fonts/Barlow/Barlow-Regular.ttf") format("truetype");
    }

    @font-face {
        font-family: "Roboto";
        src: local("Roboto"),
        url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype") ;
      font-weight: normal;
      font-style: normal;
    }
    @font-face {
      font-family: "Roboto";
      src: local("Roboto"),
      url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype") ;
      font-weight: 500;
      font-style: normal;
    }
    @font-face {
      font-family: "Roboto";
      src: local("Roboto"),
      url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype") ;
      font-weight: Bold;
      font-style: normal;
    }
    @font-face {
      font-family: "Roboto";
      src: local("Roboto"),
      url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype") ;
      font-weight: 600;
      font-style: normal;
    }

    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
      box-shadow: none !important;
      --webkit-box-shadow: none !important;
      border-radius: 0 !important;
      padding: 0 !important;
      min-height: 41px !important;
    }
    .form-item-select {
      box-shadow: none !important;
      --webkit-box-shadow: none !important;
    }
    .selected-coin-icon {
      width: auto !important;
      height: 20px !important;
    }
    .selected-coin-text {
      font-size: 16px !important;
    }
    .v-input input {
      font-size: 16px !important;
    }
    .title-desc {
      font-size: 14px;
    }

   .modal-body{
        position: relative;
    }
    .close-icon{
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .hidden-button {
      margin: 0 auto;
      width: 130px;
      position: absolute;
      left: 25%;
      right: 25%;
      top: 10%;
      opacity: 0.01;
    }
    .form-control:disabled, .form-control[readonly] {
      background-color: #fff !important;
    }
</style>
