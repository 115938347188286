var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "headerLeftContent" }, [
    _c("div", { staticClass: "balanceInfoWrap" }, [
      _c("div", { staticClass: "headlineText" }, [_vm._v("Merchant Balance")]),
      _c(
        "div",
        { staticClass: "balanceInfo" },
        [
          _vm.isLoading
            ? _c("loader")
            : _c("div", { staticClass: "totalBalance" }, [
                _c("div", { staticClass: "balanceText" }, [
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm.amountFormatter(
                          "fiat",
                          _vm.merchantTotalBalance,
                          _vm.balanceCurrencyCode
                        )
                      )
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true },
                        },
                      ],
                      attrs: {
                        title:
                          "This is your approx. FIAT + Cryptocurrency balance at the moment.",
                      },
                    },
                    [_c("v-icon", [_vm._v("info")])],
                    1
                  ),
                ]),
                _c("div", { staticClass: "balanceType" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.getBalanceList, function (item, index) {
                      return _c("li", { key: index }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeBalanceType($event)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item))]
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
        ],
        1
      ),
    ]),
    _vm.env !== "prod"
      ? _c(
          "div",
          { staticClass: "sandboxInfo" },
          [
            _c(
              "b-alert",
              { staticClass: "my-2", attrs: { variant: "warning", show: "" } },
              [
                _vm._v(" This is a "),
                _c("b", [_vm._v("Sandbox")]),
                _vm._v(
                  " environment, used for testing " +
                    _vm._s(_vm.appProductName) +
                    " services. "
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "accountActivateBtn" },
      [
        _vm.authenticatedUser.userVerified !== "true"
          ? _c(
              "b-button",
              {
                staticClass: "btn btn-sm btn-success",
                attrs: {
                  disabled: _vm.isActivateButtonDisabled,
                  href: _vm.appformUrl + _vm.merchantDetail.appformToken,
                  target: "_blank",
                },
              },
              [_vm._v(" Activate Your Account ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }