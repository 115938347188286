import { rejectError } from "@/helpers";
import axiosInstance from '../../services';
import moment from 'moment';

export default {
  namespaced: true,
  state: {
    orders: [],
    orderPrices: {},
    refundPrices: {},
    providerFees: {},
    createOrderResponse: {},
    orderDetail: {},
    depositDetail: {},
    orderAcceptResponse: {},
    orderRefundResponse: {},
    createWithdrawOrderResponse: {},
    withdrawTradePrice: {},
    txDetailUrl: {
      ADA: "https://blockchair.com/cardano/transaction/",
      ALGO: "https://algoexplorer.io/tx/",
      AVAX: "https://explorer.avax.network/tx/",
      BCH: "https://blockchair.com/bitcoin-cash/transaction/",
      BTC: "https://blockchair.com/bitcoin/transaction/",
      DOGE: "https://blockchair.com/dogecoin/transaction/",
      DOT: "https://polkascan.io/polkadot/transaction/",
      EOS: "https://blockchair.com/eos/transaction/",
      ETH: "https://blockchair.com/ethereum/transaction/",
      IOTA: "https://explorer.iota.org/mainnet/search/",
      LINK: "https://blockchair.com/ethereum/transaction/",
      LTC: "https://blockchair.com/litecoin/transaction/",
      SOL: "https://blockchair.com/solana/transaction/",
      TRX: "https://tronscan.org/#/transaction/",
      USDC: "https://blockchair.com/ethereum/transaction/",
      USDT: "https://blockchair.com/ethereum/transaction/",
      XLM: "https://blockchair.com/stellar/transaction/",
      XRP: "https://blockchair.com/ripple/transaction/",
    },
    billDetail: {},
  },
  getters: {
    txDetailUrl(state) {
      return state.txDetailUrl;
    },
    getOrders(state) {
      const { content, totalElements, size, totalPages } = state.orders;
      let orderList = [];
      if (content) {
        content.forEach(element => {
          if (element.type === "PAYMENT") {
            element.type = "RECEIVE PAYMENT";
          } else if (element.type === "DEPOSIT") {
            element.type = "RECEIVE DEPOSIT";
          } else if (element.type === "COIN_STORE") {
            element.type = "COIN STORE";
          }
          element.createdAt = moment(element.createdAt).format(
            "DD-MM-YYYY, HH:mm:ss"
          );
        });
        content.forEach(element => {
          if (element.type === "BUY" || element.type === "SELL") {
            orderList.push(element);
          } else {
            orderList.push(element);
          }
        });
      }

      return { content: orderList, totalElements, size, totalPages };
    },
    getOrderPrices(state) {
      const orderPrices = state.orderPrices;
      if (orderPrices) {
        const {
          cryptocurrency,
          cryptocurrency_amount,
          total_amount,
          currency,
          token,
          withdraw_crypto_fee,
          withdraw_fiat_fee,
          exclude_withdraw_fee
        } = orderPrices;
        return {
          cryptocurrency,
          cryptocurrency_amount,
          total_amount,
          currency,
          token,
          withdraw_crypto_fee,
          withdraw_fiat_fee,
          exclude_withdraw_fee
        };
      } else {
        return null;
      }
    },
    getRefundPrices(state) {
      const {
        cryptocurrency,
        cryptocurrency_amount,
        total_amount,
        currency,
        token
      } = state.refundPrices;
      return {
        cryptocurrency,
        cryptocurrency_amount,
        total_amount,
        currency,
        token
      };
    },
    getCreateOrderResponse(state) {
      const {
        cryptocurrency,
        cryptocurrency_amount,
        total_amount,
        currency,
        token
      } = state.createOrderResponse;
      return {
        cryptocurrency,
        cryptocurrency_amount,
        total_amount,
        currency,
        token
      };
    },
    getOrderDetail(state) {
      return state.orderDetail;
    },
    getDepositDetail(state) {
      return state.depositDetail;
    },
    acceptOrder(state) {
      return state.orderAcceptResponse;
    },
    refundOrder(state) {
      return state.orderRefundResponse;
    },
    getCreateWithdrawOrderResponse(state) {
      const {
        cryptocurrency,
        cryptocurrency_amount,
        total_amount,
        currency,
        token
      } = state.createWithdrawOrderResponse;
      return {
        cryptocurrency,
        cryptocurrency_amount,
        total_amount,
        currency,
        token
      };
    },
    getProviderFees(state) {
      return state.providerFees;
    },
    getWithdrawTradePrices(state) {
      return state.withdrawTradePrice;
    },
    getBillDetail(state) {
      return state.billDetail;
    },
  },
  actions: {
    getOrders({ commit }, data) {
      const { page, filters, limit } = data;
      const {
        orderId,
        searchText,
        customerName,
        referenceId,
        minOrderAmount,
        maxOrderAmount,
        minCryptoCurrencyAmount,
        maxCryptoCurrencyAmount,
        cryptoCurrency,
        type,
        txId,
        status,
        fromDate,
        toDate,
        currency,
        makePaymentParameter,
        customerEmail,
        blockchainAddress,
        isOverPaid,
        isUnderPaid,
        integration_ref_id
      } = filters;
      const fromDateFormated = fromDate
        ? moment(fromDate).format("YYYY-MM-DD HH:mm:ss")
        : null;
      const toDateFormated = toDate
        ? moment(toDate)
            .add(23, "hours")
            .add(59, "minutes")
            .add(59, "seconds")
            .format("YYYY-MM-DD HH:mm:ss")
        : null;

      const postData = {
        orderId,
        searchText,
        customerName: customerName !== "" ? customerName : null,
        referenceId: referenceId !== "" ? referenceId : null,
        minOrderAmount,
        maxOrderAmount,
        minCryptoCurrencyAmount,
        maxCryptoCurrencyAmount,
        fromDate: fromDateFormated,
        toDate: toDateFormated,
        txId,
        cryptoCurrency: cryptoCurrency == undefined ? null : cryptoCurrency !== "ALL" && cryptoCurrency.code !== 'ALL' ? cryptoCurrency.code : null,
        type: type.value !== "ALL" ? type.value : null,
        status: status == undefined ? null : status.name !== "ALL" ? status.name.replace(/\s+/g, '_') : null,
        currency: currency == undefined ? null : currency !== 'ALL' && currency.code !== "ALL" ? currency.code : null,
        makePaymentParameter,
        customerEmail,
        blockchainAddress,
        isOverPaid,
        isUnderPaid,
        integration_ref_id
      };

      return axiosInstance
        .post(`/orders/panel/list?page=${page}&size=${limit}`, postData)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log( response.data);
            }
            response.data.content.map(item => {
              if(item.status === 'REFUNDABLE') {
                item.isLowAmount = item.refundCost * 1.1 > item.refundableAmount
              }
            })
            commit("setOrders", response.data);
          } else {
            rejectError(response.message);
          }
          return response;
        })
        .catch(err => rejectError(err));
    },
    getReceivePaymentOrders({}, data) {
      const { page, limit } = data;
      return axiosInstance
        .post(`/orders/panel/receive-payment/history?page=${page}&size=${limit}`, {})
        .then(res => {
          const response = res.data.data;
          const { content, totalElements, size, totalPages } = response;
          let orderList = [];
          if (content) {
            content.forEach(element => {
              if (element.type === "PAYMENT") {
                element.type = "RECEIVE PAYMENT";
              } else if (element.type === "DEPOSIT") {
                element.type = "RECEIVE DEPOSIT";
              } else if (element.type === "COIN_STORE") {
                element.type = "COIN STORE";
              }
              element.createdAt = moment(element.createdAt).format(
                "DD-MM-YYYY, HH:mm:ss"
              );
            });
            content.forEach(element => {
              if (element.type === "BUY" || element.type === "SELL") {
                orderList.push(element);
              } else {
                orderList.push(element);
              }
            });
          }

          return { content: orderList, totalElements, size, totalPages };

        })
        .catch(err => rejectError(err));
    },
    getOrderPrices({ commit }, data) {
      return axiosInstance
        .post(`/orders/panel/prices`, data)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.debug(response.data);
            }

            commit("setOrderPrices", response.data);
          } else {
            rejectError(res);
          }
          return response;
        })
        .catch(err => rejectError(err));
    },
    getBuySellOrderPrices({ commit }, data) {
      return axiosInstance
        .post(`/orders/panel/buy-sell/prices`, data)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.debug(response.data);
            }

            commit("setOrderPrices", response.data);
          } else {
            rejectError(res);
          }
          return response;
        })
        .catch(err => rejectError(err));
    },
    getProviderFees({ commit }, data) {
      return axiosInstance
        .get(`/orders/panel/providerFee`)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            commit("setProviderFees", response.data);
            return response;
          } else {
            rejectError("there is an unexpected error");
          }
        })
        .catch(err => rejectError(err));
    },
    createOrder({ commit }, data) {
      return axiosInstance
        .post(`/orders/panel/create`, data)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }
            commit("setCreateOrder", response.data);
          } else {
            rejectError("there is an unexpected error");
          }
        })
        .catch(err => rejectError(err));
    },
    getOrderDetail({ commit }, data) {
      const { id } = data;

      return axiosInstance
        .get(`/orders/panel/detail/${id}`)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            commit("setOrderDetail", response.data);
          } else {
            rejectError("there is an unexpected error");
          }
          return response;
        })
        .catch(err => rejectError(err));
    },
    getDepositDetail({ commit }, data) {
      const { id } = data;

      return axiosInstance
        .get(`/orders/panel/transaction-detail/${id}`)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            commit("setDepositDetail", response.data);
          } else {
            rejectError("there is an unexpected error");
          }
          return response;
        })
        .catch(err => rejectError(err));
    },
    accept({ commit }, data) {
      const { order_id, token } = data;
      const postData = {
        order_id,
        token
      }
      return axiosInstance
        .post(`/payments/panel/accept/refundable-payment`, postData)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            commit("setOrderAccept", response.data);
          } else {
            rejectError("there is an unexpected error");
          }
        })
        .catch(err => rejectError(err));
    },
    refund({ commit }, data) {
      return axiosInstance
        .post(`/payments/panel/refund`, data)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            commit("setOrderRefund", response.data);
          } else {
            rejectError("there is an unexpected error");
          }
        })
        .catch(err => rejectError(err));
    },
    refundDeposit({ commit }, data) {
      return axiosInstance
        .post(`/orders/panel/deposit-refund`, data)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            commit("setOrderRefund", response.data);
          } else {
            rejectError("there is an unexpected error");
          }
        })
        .catch(err => rejectError(err));
    },
    getOrderLimit({ commit }, data) {

      return axiosInstance
        .post(`/orders/panel/currency/limit`, data)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            return response.data
          } else {
            rejectError("there is an unexpected error");
          }

        })
        .catch(err => rejectError(err));
    },
    getRefundPrices({ commit }, data) {
      return axiosInstance
        .post(`/payments/panel/accept/price`, data)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.debug(response.data);
            }

            commit("setRefundPrices", response.data);
          } else {
            rejectError(res);
          }
        })
        .catch(err => rejectError(err));
    },
    createWithdrawOrder({ commit }, data) {
      return axiosInstance
        .post(`/orders/panel/coin-withdraw`, data)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.log(response.data);
            }
            commit("setCreateOrder", response.data);
          } else {
            rejectError("there is an unexpected error");
          }
        })
        .catch(err => rejectError(err));
    },
    getWithdrawTradePrices({ commit }, data) {
      data.check_limits = true;
      return axiosInstance
        .post(`/orders/panel/coin-withdraw/price`, data)
        .then(res => {
          const response = res.data;

          if (response.code === "00" && response.status === "APPROVED") {
            if (process.env.NODE_ENV === "development") {
              window.console.debug(response.data);
            }

            commit("setWithdrawTradePrice", response.data);
          } else {
            rejectError(res);
          }
        })
        .catch(err => rejectError(err));
    },
    getRefundAmount({ commit }, data) {
      const { id } = data;

      return axiosInstance
        .get(`/orders/panel/getOrderRefundByOrderId/${id}`)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {
            return response.data.cryptocurrencyAmount
          } else {
            rejectError("there is an unexpected error");
          }
          return response;
        })
        .catch(err => rejectError(err));
    },
    getBillDetail({ commit }, data) {
      const { id } = data;
      commit("setBillDetail", {});

      return axiosInstance
        .get(`/orders/panel/bill/${id}`)
        .then(res => {
          const response = res.data;
          if (response.code === "00" && response.status === "APPROVED") {

            if (response.data) {
              commit("setBillDetail", response.data);
            }

          } else {
            rejectError("there is an unexpected error");
          }
          return response;
        })
        .catch(err => rejectError(err));
    },
    async getAvailableBalance({commit}, data) {
      return  axiosInstance.post('/orders/panel/available-amount', data)
    }
  },
  mutations: {
    setOrders(state, orders) {
      return (state.orders = orders);
    },
    setOrderPrices(state, orderPrices) {
      return (state.orderPrices = orderPrices);
    },
    resetOrderPrices(state) {
      return (state.orderPrices = {});
    },
    setCreateOrder(state, response) {
      return (state.createOrderResponse = response);
    },
    setOrderDetail(state, response) {
      return (state.orderDetail = response);
    },
    setDepositDetail(state, response) {
      return (state.depositDetail = response);
    },
    setOrderAccept(state, response) {
      return (state.orderAcceptResponse = response);
    },
    setOrderRefund(state, response) {
      return (state.orderRefundResponse = response);
    },
    setRefundPrices(state, refundPrices) {
      return (state.refundPrices = refundPrices);
    },
    setProviderFees(state, providerFees) {
      return (state.providerFees = providerFees);
    },
    setCreateWithdrawOrder(state, response) {
      return (state.createWithdrawOrderResponse = response);
    },
    setWithdrawTradePrice(state, response) {
      return (state.withdrawTradePrice = response);
    },
    setBillDetail(state, response) {
      return (state.billDetail = response);
    },
  }
};
